import {backendApi} from "../../backend-api-sr"

export const kitchenApiAyola = (() =>{
    const fetchOrderHeaderDetail = ( async (reqBody) => {
        var url = '/api/ayola/order'
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const updateOrderItem = ( async (reqBody) => {
        var url = '/api/ayola/order/resto-item-status'
        return await backendApi.updateCore(url, reqBody, false, false, false)
    })
    return {fetchOrderHeaderDetail, updateOrderItem};
})()