<template>
  <v-container fluid style="margin-bottom: 100px;">
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="breadcumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.href"
              class="text-subtitle-2 crumb-item"
              :disabled="item.disabled"
              exact
              >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
          <v-col cols="12">
            <v-card 
              class="elevation-12 rounded-l"
              style="border-top: 0px solid #ffffff"
            >
              <v-alert 
                dense 
                color="#57c0ac">
                <v-card-title >
                  <v-row>
                    <v-col cols="6">
                      <span class="font-color">{{ this.titleCard }}.   {{ date }}</span>
                    </v-col>
                    <v-col cols="6" class="text-end font-color">
                      {{ "Outstanding Order" }}
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-alert>
                <!-- <v-card class="mb-2">
                    <v-card-text> 
                      <h4 class="text-center" style="font-weight: 600;  color: #009688; margin-bottom: 15px; margin-top: 2px;" >
                        {{ "Outstanding Order" }}
                      </h4>
                    </v-card-text>
                </v-card> -->
                <v-dialog 
                  v-model="dialog"
                  hide-overlay
                  max-width="500"
                  transition="dialog-bottom-transition">
                  <template>
                    <v-card>
                      <v-card-title class="text-center" style="background-color:#009688;">
                      <v-row align="center" justify="center">
                          <v-col cols="12">
                              <h5 style="font-weight:200; color: #fff;">
                                Process Kitchen
                              </h5>
                          </v-col>
                      </v-row>  
                      </v-card-title>
                        <v-card-text>
                        <v-container fluid class="fill-height">
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" mb="5">
                                    <h6 style="margin-top: 8px">{{ dialogMessage }}</h6>
                                </v-col>
                            </v-row> 
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                          <v-btn style="color:#ffffff; text-white" color="#009688" @click="confrim()">Yes</v-btn>
                          <v-btn style="color:#ffffff; text-white" color="#009688" @click="close">No</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              <v-container fluid>
                <v-row>
                    <v-col v-for="(group, index) in groupedTransactions" :key="index" cols="12" md="4">
                    <template>
                      <!-- elevation=0  color="#e0f3f1"  -->
                      <v-card class="table-card">
                        <v-alert dense  color="#57c0ac" class="ma-0">
                          <v-card-title 
                            class="justify-center ma-0 table-header-font"
                            >
                                {{ group[0].location }}
                          </v-card-title>
                        </v-alert>
                        <v-data-table
                          class="ma-0"
                          style="max-height: 300px;"
                          :headers="trimmedHeaders"
                          :items="group"
                          hide-default-footer
                          disable-pagination
                          calculate-width="false"
                        >
                        <template v-slot:[`item.description`]="{ item }">
                          <div>
                            {{ item.description }}
                            <br v-if="item.remark"> 
                            {{ item.remark }}
                          </div>
                        </template>
                          <template v-slot:[`item.action`]="{ item }" class="center-text">
                            <!-- <v-tooltip bottom>
                              <template v-slot:activator="{ on, }">
                                <v-icon
                                  v-if="item.stat == 'O'"
                                  color="green"
                                  large
                                  @click="cook(item)" v-on="on">
                                >
                                mdi-microwave
                                </v-icon>
                              </template>
                              <span>Cook</span>
                            </v-tooltip> -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, }">
                                  <v-icon
                                    v-if="item.stat == 'K'"
                                    color="#57c0ac"
                                    @click="deliver(item)" v-on="on"
                                    >
                                  mdi-check-circle-outline
                                  </v-icon>
                              </template>
                              <span>Done</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-card>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
      </v-col>
      <v-snackbar 
        v-model="snackbar.visible" 
        :color="snackbar.color" 
        :multi-line="snackbar.mode === 'multi-line'" 
        :timeout="snackbar.timeout" 
        :top="snackbar.position === 'top'">
        <v-layout 
          align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
              <div>
              <strong>{{ snackbar.title }}</strong>
              </div>
              <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import { kitchenApiAyola } from "../../../backend-api/ayola/order/kitchen-api-ayola";

export default {
    name: "KitchenAyola",
    components: {},
    props: {
      whId: String,
    },
    data() {
        return {
          breadcumbs: [
            {
              text: "AYOLA",
              disabled: false,
              href: "/admin/ayala",
            },
            {
              text: 'Emerald Kitchen',
              disabled: true,
              href: 'breadcrumbs_link_2',
            },
          ],
          snackbar: {
            color: null,
            icon: null,
            mode: null,
            position: "top",
            text: null,
            timeout: 7500,
            title: null,
            visible: false
          },
          headers: [
            { text: 'Menu & Note', align: 'start', value: 'description' },
            // { text: 'Remark', value: 'remark' },
            { text: 'Qty', value: 'tr_qty',},
            { text: 'Action',  value: 'action', sortable: false,},
          ],
          salesId:'',
          titleCard:'',
          isitabel: [],
          date:'',
          totalItems: 10,
          options: {},
          loading: false, 
          dialog: false,
          dialogMessage:'',
          dialogBody:[],
          // pagination: 1,
          name:'',
          interval:null
        }
    },
    created() {
      this.initView();
      this.reset();
      this.fetchOrder();
      this.interval = setInterval(() =>{
        this.fetchOrder();
      }, 30000); 
    },
    mounted(){
       this.date = Intl.DateTimeFormat("id", {timeZone: "Asia/Jakarta",day: "numeric",month: "long",weekday: "long",year: "numeric",}).format();     
    },
    destroyed(){
      clearInterval(this.interval)
      this.reset();
    },
    computed: {
      groupedTransactions() {
        var temp = _.groupBy(this.isitabel, 'c_id')
        var tempTwo = _.sortBy(temp, group => {
          return _.minBy(group, 'dt_trans').dt_trans
        })
        tempTwo.forEach(group => {
          group.forEach(transaction => {
            if (transaction.sales_id == 'FBSRV') {
              transaction.location = "Room " + transaction.customer_id
            } else {
              transaction.location = "Table " + transaction.c_id
            }
          })
        })
        return tempTwo;
      },
      trimmedHeaders() {
        return this.headers.map(header => {
          return {
            text: header.text.trim(),
            value: header.value.trim(),
          }
        })
      },
    },
    methods:{
      initView(){
        if (this.whId === "TS") {
          this.titleCard = "Topaz Sky Lounge Kitchen";
          this.salesId = 'FBSKY';
          this.breadcumbs = [
            {
              text: "AYOLA",
              disabled: false,
              href: "/admin/ayala",
            },
            {
              text: "Topaz Sky Lounge",
              disabled: true,
              href: "",
            },
          ];
        } else if (this.whId === "EM") {
          this.titleCard = "Emerald Bistro Kitchen";
          this.salesId = 'FBEMR';
          this.breadcumbs = [
            {
              text: "AYOLA",
              disabled: false,
              href: "/admin/ayala",
            },
            {
              text: "Emerald Bistro",
              disabled: true,
              href: "",
            },
          ];
        } else {
          this.titleCard = "Room Service Kitchen";
          this.salesId = 'FBSRV';
          this.breadcumbs = [
            {
              text: "AYOLA",
              disabled: false,
              href: "/admin/ayala",
            },
            {
              text: "Room Service",
              disabled: true,
              href: "",
            },
          ];
        }
      },
      reset(){
        this.name='';
        this.interval=null;
        this.date='';
        this.isitabel='';
        this.dialog=false;
        this.resetDialogContent();
      },
      resetDialogContent(){
        this.dialogMessage='';
        this.dialogBody=[];
      },
      cook(item){
        this.dialog = true;
        this.dialogMessage = `Ready to cook ${item.description}`
        if(item.c_id > 0 ){
          this.dialogMessage = `${this.dialogMessage} for table ${item.c_id} at ${item.sales_id.trim()} as much as ${item.tr_qty} serving`
        } else
        if(item.customer_id > 0 ){
          this.dialogMessage = `${this.dialogMessage} for room ${item.customer_id} as much as ${item.tr_qty} serving`
        }
        this.dialogBody = {
          office_id: item.office_id,
          doc_id: item.doc_id,
          tr_seq: item.tr_seq,
          stat : item.stat,
          stat_next:'K',
        };
      },
      deliver(item){
        this.dialog = true;
        this.dialogMessage = `Ready to deliver ${item.description}`
        if(item.c_id > 0 ){
          this.dialogMessage = `${this.dialogMessage} for table ${item.c_id} at ${item.sales_id.trim()} as much as ${item.tr_qty} serving`
        }
        if(item.customer_id > 0 ){
          this.dialogMessage = `${this.dialogMessage} for room ${item.customer_id} as much as ${item.tr_qty} serving`
        }
        this.dialogBody = {
          office_id: item.office_id,
          doc_id: item.doc_id,
          tr_seq: item.tr_seq,
          stat : item.stat,
          stat_next:'F',
        };
      },
      fetchOrder(){
        this.loading = true;
        var reqBody = {
          office_id: '1',
          sales_id: this.salesId,
          // stat O harusnya g mucnul, masalahnya cook itu stat apa, delivered apa
          stat_item : ['K'],
          order_by:'order_mast.dt_trans',
          sort_order:'desc'
        };
        this.isitabel = [];
        kitchenApiAyola.fetchOrderHeaderDetail(reqBody).then(
          (response) => {
            this.isitabel = response.data.data;
          }
        );
        this.loading = false;
      },
      menuConfirm(item){console.log(item)},
      close(){
        this.dialog = false;
      },
      confrim(){
        kitchenApiAyola.updateOrderItem(this.dialogBody).then((response) => {
            if(response.data.status_code == 200){
              this.resetDialogContent();
              this.dialog = false;
              this.fetchOrder();
              this.snackbar = {
                  color: "success",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 6000,
                  title: response.data.status,
                  text: response.data.message,
                  visible: true
                };
            } else {
              this.resetDialogContent();
              this.dialog = false;
              this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 6000,
                  title: response.data.status,
                  text: response.data.message,
                  visible: true
                };
            }
          })
      }   
    },
    watch: {
        options: {
            handler () {
               
            },
            
        },
      
    }

}
</script>

<style>
.center-text {
  text-align: center;
}
.font-color{
  color: #ffffff;
}
.ayola-color{
  color: #57c0ac;
}
.table-card{
  height: 300px; 
  overflow:auto;
}
.table-header-font{
  font-size: smaller; 
  color: #ffffff; 
  padding:0;
}
</style>